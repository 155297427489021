<template>
  <div style="width: 400px">
    <h2 class="page-title">Feedback</h2>
    <form
      name="feedback"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      @submit.prevent="handleSubmit"
    >
      <input type="hidden" name="form-name" value="feedback" />
      <p>
        If you'd prefer, email us at
        <a href="mailto:skyswarm@sky360.com.au">skyswarm@sky360.com.au</a>
      </p>
      <b-form-input
        class="mb-2"
        id="subject"
        v-model="form.subject"
        placeholder="Enter subject"
        required
      ></b-form-input>
      <b-form-textarea
        class="mb-2"
        id="message"
        v-model="form.message"
        placeholder="Enter message..."
        rows="5"
        max-rows="8"
      ></b-form-textarea>
      <b-button variant="outline-dark" @click="handleSubmit">Submit</b-button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Account",
  data() {
    return {
      form: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
    };
  },
  methods: {
    resetForm() {
      this.$set(this.form, "name", "");
      this.$set(this.form, "email", "");
      this.$set(this.form, "subject", "");
      this.$set(this.form, "message", "");
    },
    encode(data) {
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    },
    async handleSubmit() {
      const axiosConfig = {
        header: { "Content-Type": "application/x-www-form-urlencoded" },
      };
      this.form.name = this.$auth.user.name;
      this.form.email = this.$auth.user.email;
      axios
        .post(
          "/",
          this.encode({
            "form-name": "feedback",
            ...this.form,
          }),
          axiosConfig
        )
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.$vs.notification({
              color: "success",
              duration: 8000,
              title: "Feedback sent!",
              text: "Thanks for reaching out. We'll be in touch :)",
            });
            this.resetForm();
          } else {
            this.$vs.notification({
              color: "danger",
              duration: "none",
              title: "Uh oh! Something went wrong!",
              text:
                "An error occurred while submitting your feedback. Try again, or email us at skyswarm@sky360.com.au",
            });
          }
        });
    },
  },
};
</script>

<style>
.vs-button {
  margin: 0 0 0 0;
}
</style>
