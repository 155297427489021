<template>
  <div>
    <div>
      <h2 style="padding-bottom: 10px; display: inline-block; font-weight: 600">
        Audits
      </h2>

      <b-button
        style="float: right; margin-top: 0px"
        variant="outline-dark"
        class="mr-2"
        v-b-tooltip.hover
        title="Refresh"
        @click="loadData()"
        ><b-icon-arrow-clockwise></b-icon-arrow-clockwise
      ></b-button>
    </div>
    <transition name="slide-fade">
      <vue-good-table
        v-if="this.audits"
        :rows="audits"
        styleClass="vgt-table"
        :columns="columns"
      >
        <template slot="table-row" slot-scope="props">
          <span
            v-if="props.column.field == 'actions'"
            style="margin-left: auto; text-align: right !important"
          >
            <b-button
              variant="outline-dark"
              size="sm"
              style="margin-top: -10px; margin-bottom: -10px; margin-right: 5px"
              v-if="
                props.column.field == 'actions' && !showReportButton(props.row)
              "
              v-b-tooltip.hover.left="'Report (PDF)'"
              @click="report(props.row, props.index, $event.target)"
            >
              <b-icon-file-earmark-text></b-icon-file-earmark-text>
            </b-button>
            <b-button
              variant="outline-dark"
              size="sm"
              v-if="props.column.field === 'actions'"
              style="margin-top: -10px; margin-bottom: -10px"
              :to="'/audits/' + props.row.id"
            >
              <b-icon-arrow-right></b-icon-arrow-right>
            </b-button>
          </span>
          <span v-else-if="props.column.field === 'findings'">
            {{ props.row.closedFindings.aggregate.count }} /
            {{ props.row.totalFindings.aggregate.count }}
          </span>
          <span v-else-if="props.column.field === 'status'">
            <b-icon
              :icon="auditStatusIcon(props.row.audit_closed).icon"
              :variant="auditStatusIcon(props.row.audit_closed).variant"
              class="mr-1"
            ></b-icon>
            {{ props.row.audit_closed ? "Closed" : "Open" }}
          </span>
          <span v-else-if="props.column.field === 'audit_expiry_date'">
            {{ props.row.audit_expiry_date | formatDate }}
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </transition>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Audits",
  components: {},
  data() {
    return {
      fill: { gradient: ["red", "green", "blue"] },
      audits: null,
      columns: [
        {
          field: "audit_name",
          label: "Audit",
        },
        {
          field: "findings",
          label: "Closed / total findings",
        },
        {
          field: "status",
          label: "Status",
        },
        {
          field: "audit_expiry_date",
          label: "Expiry date",
          type: "date",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "dd/MM/yyyy",
        },
        {
          field: "actions",
          label: "",
          sortable: false,
          type: "number",
          width: "105px",
        },
      ],
      fields: [
        {
          key: "audit_name",
          sortable: true,
        },
        {
          key: "closedfindings",
          label: "Closed / total findings",
        },
        {
          key: "audit_closed_computed",
          label: "Status",
          sortable: true,
        },
        {
          key: "audit_expiry_date_formatted",
          label: "Expiry date",
        },
        {
          class: "actions-column",
          key: "report",
          label: "",
        },
        {
          class: "actions-column",
          key: "open",
          label: "",
        },
      ],
    };
  },
  filters: {
    formatDate: function (date) {
      if (date != undefined) {
        const day = date.substring(8);
        const month = date.substring(5, 7);
        const year = date.substring(0, 4);
        return `${day}/${month}/${year}`;
      }
    },
  },
  methods: {
    closedFindings(item) {
      return `${item.closedFindings.aggregate.count} / ${item.totalFindings.aggregate.count}`;
    },
    showReportButton(item) {
      return item.audit_report === null ? true : false;
    },
    auditStatus(status) {
      return status.audit_closed === false ? "Open" : "Closed";
    },
    auditStatusIcon(status) {
      return status
        ? {
            icon: "check-circle-fill",
            variant: "success",
          }
        : {
            icon: "exclamation-circle-fill",
            variant: "danger",
          };
    },
    sendNotification(color, title, text, duration) {
      this.$vs.notification({
        color: color,
        duration: duration ? duration : 8000,
        title: title,
        text: text,
      });
    },
    formatDate(date) {
      if (date != undefined) {
        const day = date.substring(8);
        const month = date.substring(5, 7);
        const year = date.substring(0, 4);
        return `${day}/${month}/${year}`;
      }
    },
    async report(item) {
      const token = await this.$auth.getTokenSilently();
      axios
        .request({
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            key: item.audit_report.s3_key,
          },
          url: "https://api.skyswarm.sky360.com.au/v1/audit-report/download",
        })
        .then((response) => {
          window.location.href = response.data.downloadURL;
          if (response.status === 200) {
            this.$vs.notification({
              color: "success",
              duration: "5000",
              text: "Downloading audit report...",
            });
          } else {
            this.$vs.notification({
              color: "danger",
              progress: "auto",
              duration: "8000",
              title: "Uh oh! Something bad has happened...",
              text: "An error occured while downloading the audit report",
            });
          }
        });
    },
    async loadData() {
      const token = await this.$auth.getTokenSilently();
      axios
        .request({
          method: "post",
          url: "https://api.skyswarm.sky360.com.au/v1/graphql",
          data: {
            query: `query AuditsTable {
  audits {
    audit_closed
    audit_name
    id
    audit_expiry_date
    totalFindings: audit_findings_aggregate {
      aggregate {
        count
      }
    }
    closedFindings: audit_findings_aggregate(where: {_and: {finding_closed: {_eq: true}}}) {
      aggregate {
        count
      }
    }
    audit_report {
      audit_id
      id
      s3_key
    }
  }
}`,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.audits = response.data.data.audits;
        });
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style>
.fa {
  font-size: 16px;
  padding-right: 2px;
}
.redicon {
  color: red;
}
.greenicon {
  color: limegreen;
}
.table {
  background-color: #ffffff !important;
}
.actions-column {
  width: 100px;
  min-width: 100px;
  text-align: right;
}
tr {
  border: 1px solid rgba(0, 0, 0, 0.125);
}
</style>
